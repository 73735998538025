import { useState, useEffect } from 'react';
import Event from './Event';
import { ExpandableList } from '../../../components/ui/List';
const Events = () => {
    const [events, setEvents] = useState<any[]>([]);
    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const response = await fetch('/api/events');
    //             const data = await response.json();
    //             setEvents(data);
    //         } catch (error) {
    //             console.error('Error fetching events:', error);
    //         }
    //     };

    //     fetchData();
    // }, []);

    return (
        <div>
            <ExpandableList listName="Events" items={events.map((event) => ({
                itemName: event.event_name,
                itemContent: <Event key={event.prime_event_id} event={event} />
            }))} />
        </div>
    );
};

export default Events;
