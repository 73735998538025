import { useState, useEffect } from "react"
import { DropdownButton } from "./ui/Button"
import { IssueStatus, TaskAction } from "../types/jira.types"
import { ActionTagsInput } from "./ui/Form"
import { ActionType } from "../types/shared.types"
import { List } from "./ui/List"
const LOOKUP_ITEMS = [
    { id: 0, name: 'Lookup', valueInput: false, jiraConditional: false, color: 'secondary' },
    { id: 1, name: 'Event Value', valueInput: true, jiraConditional: false, color: 'primary' },
    { id: 2, name: 'Param Value', valueInput: true, jiraConditional: false, color: 'primary' },
    { id: 3, name: 'Jira Status', valueInput: false, jiraConditional: true, color: 'primary' },
    { id: 4, name: 'Connector', valueInput: true, jiraConditional: false, color: 'primary' },
    { id: 5, name: 'Authentication', valueInput: true, jiraConditional: false, color: 'primary' },
    { id: 6, name: 'Authentication Method', valueInput: true, jiraConditional: false, color: 'primary' },
]

const CONDITIONAL_ITEMS = [
    { id: 0, name: 'Conditional', valueInput: false, color: 'secondary' },
    { id: 1, name: 'Equal To', valueInput: true, color: 'success' },
    { id: 2, name: 'Not Equal To', valueInput: true, color: 'primary' },
    { id: 3, name: 'Greater Than', valueInput: true, color: 'primary' },
    { id: 4, name: 'Less Than', valueInput: true, color: 'primary' },
    { id: 5, name: 'Null', valueInput: false, color: 'danger' }
]

const ACTION_ITEMS = [
    { id: 0, name: 'Run Script', valueInput: false, actionInput: true, color: 'secondary' },
    { id: 1, name: 'Update Jira Status', valueInput: false, jiraInput: true, color: 'primary' }
]

// const ACTIONS = [
//     { id: 0, name: 'Action', valueInput: false, color: 'secondary' },
//     { id: 1, name: 'Run Script', valueInput: true, color: 'primary' }
// ]

const saveAction = (action: ActionType, setValidationError: (error: string) => void, onSave: (action: TaskAction) => void, setShowModal: (show: boolean) => void) => {
    const selectedLookup = LOOKUP_ITEMS.find(item => item.name === action.lookup_category_txt);
    const selectedConditional = CONDITIONAL_ITEMS.find(item => item.name === action.conditional_category_txt);
    const selectedActionItem = ACTION_ITEMS.find(item => item.name === action.action_category_txt);

    if (selectedLookup?.id === 0) {
        setValidationError('Lookup is required');
    } else if (selectedLookup?.valueInput && action.lookup_txt === '') {
        setValidationError('Lookup value is required');
    } else if (selectedConditional?.id === 0 && !selectedLookup?.jiraConditional) {
        setValidationError('Conditional is required');
    } else if (selectedConditional?.valueInput && action.conditional_txt === '') {
        setValidationError('Conditional value is required');
    } else if (selectedActionItem?.actionInput && action.action_txt === '') {
        setValidationError('Action value is required');
    } else {
        fetch('/api/actions', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                prime_action_id: action.prime_action_id,
                lookup_category_txt: selectedLookup?.id,
                lookup_txt: action.lookup_txt,
                conditional_category_txt: selectedConditional?.id,
                conditional_txt: action.conditional_txt,
                action_category_txt: selectedActionItem?.id,
                action_txt: action.action_txt,
                failure_txt: action.failure_txt
            })
        })
        .then(response => response.json())
        .then(data => {
            onSave(data);
            setShowModal(false);
        })
        .catch((error) => {
            setValidationError(error.message);
        });
    }
}

const getStatusColor = (status: string) => {
    return status === 'Completed' ? 'success' : status === 'Pending' ? 'secondary' : status === 'Needs Intervention' ? 'danger' : status === 'Deployed' ? 'success' : 'primary';
}

export const ActionModal = ({ action, setShowModal, onSave }: { action?: ActionType, setShowModal: (show: boolean) => void, onSave: (action: ActionType) => void }) => {
    const [selectedLookup, setSelectedLookup] = useState<any>(action?.lookup_category_txt ? LOOKUP_ITEMS.find(item => item.name === action.lookup_category_txt) : LOOKUP_ITEMS[0]);
    const [selectedLookupStatus, setSelectedLookupStatus] = useState<any>();
    const [lookupValue, setLookupValue] = useState<string>(action?.lookup_txt || '');
    const [selectedConditional, setSelectedConditional] = useState<any>(action?.conditional_category_txt ? CONDITIONAL_ITEMS.find(item => item.name === action.conditional_category_txt) : CONDITIONAL_ITEMS[0]);
    const [conditionalValue, setConditionalValue] = useState<string>(action?.conditional_txt || '');
    const [validationError, setValidationError] = useState<string>('');
    const [selectedStatus, setSelectedStatus] = useState<any>();
    const [issueStatuses, setIssueStatuses] = useState<{ id: number, name: string, color: string }[]>([]);
    const [selectedActionItem, setSelectedActionItem] = useState<any>(action?.action_category_txt ? ACTION_ITEMS.find(item => item.name === action.action_category_txt) : ACTION_ITEMS[0]);
    const [actionValue, setActionValue] = useState<string>(action?.action_txt || '');

    // const [selectedLookup, setSelectedLookup] = useState<any>(action?.lookup_category_txt ? LOOKUP_ITEMS.find(item => item.name === action.lookup_category_txt) : LOOKUP_ITEMS[0]);
    // const [selectedLookupStatus, setSelectedLookupStatus] = useState<any>();
    // const [lookupValue, setLookupValue] = useState<string>('');
    // const [selectedConditional, setSelectedConditional] = useState<any>(CONDITIONAL_ITEMS[0]);
    // const [conditionalValue, setConditionalValue] = useState<string>('');
    // const [validationError, setValidationError] = useState<string>('');
    // const [selectedStatus, setSelectedStatus] = useState<any>();
    // const [issueStatuses, setIssueStatuses] = useState<{ id: number, name: string, color: string }[]>([]);
    // const [selectedActionItem, setSelectedActionItem] = useState<any>(ACTION_ITEMS[0]);
    // const [actionValue, setActionValue] = useState<string>('');

    useEffect(() => {
        const fetchData = async () => {
            const issueStatusesResponse = await fetch('/api/jira/issue-statuses');
            const issueStatusesData = await issueStatusesResponse.json();
            setIssueStatuses(issueStatusesData.map((status: { status_id: number, name: string }) => ({ id: status.status_id, name: status.name, color: getStatusColor(status.name) })));
            setSelectedLookupStatus({
                id: issueStatusesData[0].status_id,
                name: issueStatusesData[0].name,
                color: getStatusColor(issueStatusesData[0].name)
            });
        };
        fetchData();
    }, []);


    const handleLookupChange = (event: any, id: number) => {
        event.preventDefault();
        setSelectedLookup(LOOKUP_ITEMS.find(item => item.id === id));
    }

    const handleLookupValueChange = (event: any) => {
        setLookupValue(event.target.value);
    }

    const handleLookupStatusChange = (event: any, id: number) => {
        event.preventDefault();
        setSelectedLookupStatus(issueStatuses.find(item => item.id === id));
    }

    const handleConditionalChange = (event: any, id: number) => {
        event.preventDefault();
        setSelectedConditional(CONDITIONAL_ITEMS.find(item => item.id === id));
    }

    const handleConditionalValueChange = (event: any) => {
        setConditionalValue(event.target.value);
    }

    const handleActionItemChange = (event: any, id: number) => {
        event.preventDefault();
        setSelectedActionItem(ACTION_ITEMS.find(item => item.id === id));
    }

    const handleActionValueChange = (event: any) => {
        setActionValue(event.target.value);
    }

    const handleStatusChange = (event: any, id: number) => {
        event.preventDefault();
        setSelectedStatus(issueStatuses.find(item => item.id === id));
    }

    const handleAddAction = (event: any) => {
        if (selectedLookup.id === 0) {
            setValidationError('Lookup is required');
        } else if (selectedLookup.valueInput && lookupValue === '') {
            setValidationError('Lookup value is required');
        } else if (selectedConditional.id === 0 && !selectedLookup.jiraConditional) {
            setValidationError('Conditional is required');
        } else if (selectedConditional.valueInput && conditionalValue === '') {
            setValidationError('Conditional value is required');
        } else if (selectedActionItem.actionInput && actionValue === '') {
            setValidationError('Action value is required');
        } else {
            fetch('/api/actions', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    lookup: selectedLookup,
                    lookup_value: lookupValue,
                    conditional: selectedConditional,
                    conditional_value: conditionalValue,
                    action_item: selectedActionItem,
                    action_item_value: actionValue,
                    status: selectedStatus
                })
            })
            .then(response => response.json())
            .then(data => {
                onSave(data);
                setShowModal(false);
            })
            .catch((error) => {
                setValidationError(error.message);
            });
        }
    }

    return (
        <div className="modal fade show" style={{ display: 'block' }} tabIndex={-1} role="dialog">
            <div className="modal-dialog modal-lg" role="document"> {/* Added 'modal-lg' class to make the modal bigger */}
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">New Action</h5>
                        <button type="button" className="btn-close" onClick={() => setShowModal(false)} aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="d-flex align-items-center flex-wrap">
                            <span className="me-1">When</span>
                            <div className="me-1">
                                <DropdownButton selectedItem={selectedLookup} items={LOOKUP_ITEMS} onClick={handleLookupChange} />
                            </div>
                            {selectedLookup.valueInput && (
                            <div className="me-1">
                                <input type="text" className="form-control form-control-sm" value={lookupValue} onChange={handleLookupValueChange} placeholder="Key or Column Name" style={{ width: '250px', display: 'inline-block' }} />
                                </div>
                            )}
                            <span className="me-1">is</span>
                            {selectedLookup.jiraConditional && (
                                <div className="me-1">
                                    <DropdownButton selectedItem={selectedLookupStatus} items={issueStatuses} onClick={handleLookupStatusChange} />
                                </div>
                            )}
                            {!selectedLookup.jiraConditional && (
                            <div className="me-1">
                                <DropdownButton selectedItem={selectedConditional} items={CONDITIONAL_ITEMS} onClick={handleConditionalChange} />
                            </div>
                            )}
                            {selectedConditional.valueInput && (
                                <div className="me-1">
                                    <input type="text" className="form-control form-control-sm" value={conditionalValue} onChange={handleConditionalValueChange} placeholder="Value" style={{ width: '250px', display: 'inline-block' }} />
                                </div>
                            )}
                            <span className="me-1">Then</span>
                            <div className="me-1">
                                <DropdownButton selectedItem={selectedActionItem} items={ACTION_ITEMS} onClick={handleActionItemChange} />
                            </div>
                            {selectedActionItem.actionInput && (
                                <div className="me-1">
                                    <input type="text" className="form-control form-control-sm" value={actionValue} onChange={handleActionValueChange} placeholder="Value" style={{ width: '250px', display: 'inline-block' }} />
                                </div>
                            )}
                            {selectedActionItem.jiraInput && (
                                <div className="me-1">
                                    <DropdownButton selectedItem={selectedStatus} items={issueStatuses} onClick={handleStatusChange} />
                                </div>
                            )}
                        </div>
                        {validationError && <div className="text-danger">{validationError}</div>}
                    </div>
                    <div className="modal-footer d-flex justify-content-end">
                        <button className="btn btn-primary me-2" onClick={handleAddAction} >
                            Add
                        </button>
                        <button className="btn btn-secondary" onClick={() => setShowModal(false)}>
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const Action = ({ action }: { action: ActionType }) => {
    const [showModal, setShowModal] = useState(false);
    return (
        <div>
            {action.action_nm}
            <ActionTagsInput action={action} onRemove={() => {}} onEdit={() => setShowModal(true)} />

            {showModal && (
                <ActionModal setShowModal={setShowModal} onSave={() => {}} action={action} />
            )}
        </div>
    )
}

const NewAction = ({ setShowModal }: { setShowModal: (show: boolean) => void }) => {
    
    return <ActionModal setShowModal={setShowModal} onSave={() => {console.log('saved')}} />
}

export const ActionList = ({ actions }: { actions: ActionType[] }) => {
    const [actionList, setActionList] = useState<ActionType[]>(actions);
    const [showNewActionModal, setShowNewActionModal] = useState(false);
    const handleAddNewAction = () => {
        setShowNewActionModal(true);
    }
    const handleActionListChange = (data: ActionType) => {
        setActionList([...actionList, data]);
    }
    const mapEventActions = actions.map((action: any) => {
        return <Action key={action.prime_action_id} action={action} />
    });
    return (
        <div>
            <List listName="Actions" items={mapEventActions} onAddNewItem={handleAddNewAction} newItemLabel="Action" />
            {showNewActionModal && (
                <NewAction setShowModal={setShowNewActionModal} />
            )}
        </div>
    );
}

// action_nm: action.primeAction.action_nm,
//                 lookup_category_txt: action.primeAction.lookup_category_txt,
//                 lookup_txt: action.primeAction.lookup_txt,
//                 conditional_category_txt: action.primeAction.conditional_category_txt,
//                 conditional_txt: action.primeAction.conditional_txt,
//                 action_category_txt: action.primeAction.action_category_txt,
//                 action_txt: action.primeAction.action_txt,
//                 failure_txt: action.primeAction.failure_txt