import React from 'react';

const SchemaBuilder = ({ selectedSchema, onSchemaCreated }: { selectedSchema: any, onSchemaCreated: any }) => {
    return (
        <div>
            <h3>Schema Builder</h3>
        </div>
    );
}

export default SchemaBuilder;
