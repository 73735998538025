import { List } from "../../../components/ui/List";
import { ActionList } from "../../../components/Action";



const Event = ({ event }: { event: any }) => {
    return (
        <div>
            <ActionList actions={event.primeEventActions.map((action: any) => action.primeAction)} />
        </div>
    );
};


export default Event;