import { useState } from "react"
import { DropdownButton } from "../../../components/ui/Button"
import { IssueStatus, TaskAction } from "../../../types/jira.types"

const LOOKUP_ITEMS = [
    { id: 0, name: 'Lookup', valueInput: false, jiraConditional: false, color: 'secondary' },
    { id: 1, name: 'Event Value', valueInput: true, jiraConditional: false, color: 'primary' },
    { id: 2, name: 'Jira Status', valueInput: false, jiraConditional: true, color: 'primary' },
    { id: 3, name: 'Connector', valueInput: true, jiraConditional: false, color: 'primary' },
    { id: 4, name: 'Authentication', valueInput: true, jiraConditional: false, color: 'primary' },
    { id: 5, name: 'Authentication Method', valueInput: true, jiraConditional: false, color: 'primary' },
]

const CONDITIONAL_ITEMS = [
    { id: 0, name: 'Conditional', valueInput: false, color: 'secondary' },
    { id: 1, name: 'Equal To', valueInput: true, color: 'success' },
    { id: 2, name: 'Not Equal To', valueInput: true, color: 'primary' },
    { id: 3, name: 'Greater Than', valueInput: true, color: 'primary' },
    { id: 4, name: 'Less Than', valueInput: true, color: 'primary' },
    { id: 5, name: 'Null', valueInput: false, color: 'danger' }
]

const ACTION_ITEMS = [
    { id: 0, name: 'Run Script', valueInput: false, actionInput: true, color: 'secondary' },
    { id: 1, name: 'Update Jira Status', valueInput: false, jiraInput: true, color: 'primary' }
]

// const ACTIONS = [
//     { id: 0, name: 'Action', valueInput: false, color: 'secondary' },
//     { id: 1, name: 'Run Script', valueInput: true, color: 'primary' }
// ]

const getStatusColor = (status: string) => {
    return status === 'Completed' ? 'success' : status === 'Pending' ? 'secondary' : status === 'Needs Intervention' ? 'danger' : status === 'Deployed' ? 'success' : 'primary';
}

export const ActionModal = ({ setShowModal, statuses, onSave }: { setShowModal: (show: boolean) => void, statuses: IssueStatus[], onSave: (action: TaskAction) => void }) => {
    const [selectedLookup, setSelectedLookup] = useState<any>(LOOKUP_ITEMS[0]);
    const [selectedLookupStatus, setSelectedLookupStatus] = useState<any>(statuses[0]);
    const [lookupValue, setLookupValue] = useState<string>('');
    const [selectedConditional, setSelectedConditional] = useState<any>(CONDITIONAL_ITEMS[0]);
    const [conditionalValue, setConditionalValue] = useState<string>('');
    const [validationError, setValidationError] = useState<string>('');
    const [selectedStatus, setSelectedStatus] = useState<any>({
        id: statuses[0].status_id,
        name: statuses[0].name,
        color: getStatusColor(statuses[0].name)
    });
    const [issueStatuses] = useState<{ id: number, name: string, color: string }[]>(statuses.map(status => ({ id: status.status_id, name: status.name, color: getStatusColor(status.name) })));
    const [selectedActionItem, setSelectedActionItem] = useState<any>(ACTION_ITEMS[0]);
    const [actionValue, setActionValue] = useState<string>('');


    const handleLookupChange = (event: any, id: number) => {
        event.preventDefault();
        setSelectedLookup(LOOKUP_ITEMS.find(item => item.id === id));
    }

    const handleLookupValueChange = (event: any) => {
        setLookupValue(event.target.value);
    }

    const handleLookupStatusChange = (event: any, id: number) => {
        event.preventDefault();
        setSelectedLookupStatus(issueStatuses.find(item => item.id === id));
    }

    const handleConditionalChange = (event: any, id: number) => {
        event.preventDefault();
        setSelectedConditional(CONDITIONAL_ITEMS.find(item => item.id === id));
    }

    const handleConditionalValueChange = (event: any) => {
        setConditionalValue(event.target.value);
    }

    const handleActionItemChange = (event: any, id: number) => {
        event.preventDefault();
        setSelectedActionItem(ACTION_ITEMS.find(item => item.id === id));
    }

    const handleActionValueChange = (event: any) => {
        setActionValue(event.target.value);
    }

    const handleStatusChange = (event: any, id: number) => {
        event.preventDefault();
        setSelectedStatus(issueStatuses.find(item => item.id === id));
    }

    const handleAddAction = (event: any) => {
        if (selectedLookup.id === 0) {
            setValidationError('Lookup is required');
        } else if (selectedLookup.valueInput && lookupValue === '') {
            setValidationError('Lookup value is required');
        } else if (selectedConditional.id === 0 && !selectedLookup.jiraConditional) {
            setValidationError('Conditional is required');
        } else if (selectedConditional.valueInput && conditionalValue === '') {
            setValidationError('Conditional value is required');
        } else if (selectedActionItem.actionInput && actionValue === '') {
            setValidationError('Action value is required');
        } else {
            onSave({
                lookup: {value: selectedLookup.name, color: selectedLookup.color},
                lookup_value: selectedLookup.valueInput ? lookupValue : undefined,
                conditional: {value: selectedConditional.name, color: selectedConditional.color},
                conditional_value: selectedConditional.valueInput ? conditionalValue : undefined,
                action_item: {value: selectedActionItem.name, color: selectedActionItem.color},
                action_item_value: selectedActionItem.jiraInput ? {value: selectedStatus.name, color: selectedStatus.color} : selectedActionItem.actionInput ? {value: actionValue, color: 'secondary'} : undefined
            });
            setShowModal(false);
        }
    }

    return (
        <div className="modal fade show" style={{ display: 'block' }} tabIndex={-1} role="dialog">
            <div className="modal-dialog modal-lg" role="document"> {/* Added 'modal-lg' class to make the modal bigger */}
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">New Action</h5>
                        <button type="button" className="btn-close" onClick={() => setShowModal(false)} aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="d-flex align-items-center flex-wrap">
                            <span className="me-1">When</span>
                            <div className="me-1">
                                <DropdownButton selectedItem={selectedLookup} items={LOOKUP_ITEMS} onClick={handleLookupChange} />
                            </div>
                            {selectedLookup.valueInput && (
                            <div className="me-1">
                                <input type="text" className="form-control form-control-sm" value={lookupValue} onChange={handleLookupValueChange} placeholder="Key or Column Name" style={{ width: '250px', display: 'inline-block' }} />
                                </div>
                            )}
                            <span className="me-1">is</span>
                            {selectedLookup.jiraConditional && (
                                <div className="me-1">
                                    <DropdownButton selectedItem={selectedLookupStatus} items={issueStatuses} onClick={handleLookupStatusChange} />
                                </div>
                            )}
                            {!selectedLookup.jiraConditional && (
                            <div className="me-1">
                                <DropdownButton selectedItem={selectedConditional} items={CONDITIONAL_ITEMS} onClick={handleConditionalChange} />
                            </div>
                            )}
                            {selectedConditional.valueInput && (
                                <div className="me-1">
                                    <input type="text" className="form-control form-control-sm" value={conditionalValue} onChange={handleConditionalValueChange} placeholder="Value" style={{ width: '250px', display: 'inline-block' }} />
                                </div>
                            )}
                            <span className="me-1">Then</span>
                            <div className="me-1">
                                <DropdownButton selectedItem={selectedActionItem} items={ACTION_ITEMS} onClick={handleActionItemChange} />
                            </div>
                            {selectedActionItem.actionInput && (
                                <div className="me-1">
                                    <input type="text" className="form-control form-control-sm" value={actionValue} onChange={handleActionValueChange} placeholder="Value" style={{ width: '250px', display: 'inline-block' }} />
                                </div>
                            )}
                            {selectedActionItem.jiraInput && (
                                <div className="me-1">
                                    <DropdownButton selectedItem={selectedStatus} items={issueStatuses} onClick={handleStatusChange} />
                                </div>
                            )}
                        </div>
                        {validationError && <div className="text-danger">{validationError}</div>}
                    </div>
                    <div className="modal-footer d-flex justify-content-end">
                        <button className="btn btn-primary me-2" onClick={handleAddAction} >
                            Add
                        </button>
                        <button className="btn btn-secondary" onClick={() => setShowModal(false)}>
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}