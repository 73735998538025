const eventJson = '[ { "scopes": [ "https://www.googleapis.com/auth/calendar", "https://www.googleapis.com/auth/calendar.events", "https://www.googleapis.com/auth/calendar.readonly" ], "authentication_type_id": 1, "authentication_type_field_id": "18" }, { "token_url": "https://oauth2.googleapis.com/token", "authentication_type_id": 1, "authentication_type_field_id": "20" }, { "revocation_url": "https://accounts.google.com/o/oauth2/revoke", "authentication_type_id": 1, "authentication_type_field_id": "384" }, { "pkce_verifier": null, "authentication_type_id": 1, "authentication_type_field_id": "385" }, { "refresh_token_url": "https://oauth2.googleapis.com/token", "authentication_type_id": 1, "authentication_type_field_id": "387" }, { "audience": null, "authentication_type_id": 1, "authentication_type_field_id": "388" }, { "authentication_type_id": 1, "authentication_type_field_id": "390", "access_token_expiration_seconds": 3600 }, { "authentication_type_id": 1, "authentication_type_field_id": "386", "Authorization Code Grant Type": "authorization_code" }, { "authorization_url": "https://accounts.google.com/o/oauth2/v2/auth", "authentication_type_id": 1, "authentication_type_field_id": "23" }, { "authentication_type_id": 1, "authentication_type_field_id": "391", "Authentication with Access Token Header": null } ]';

const TestAuthenticationDetails = () => {
    const handleAuthenticationDetailsSubmit = (e: any) => {
        e.preventDefault();

        const formData = new FormData(e.currentTarget);
        const eventData: any = {
            eventName: "AUTHENTICATION_DETAILS",
            connectorId: formData.get("connectorId"),
            event: JSON.parse(formData.get("eventJson") as string)
        };
        
        fetch('/api/events', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(eventData)
        }).then(response => response.json())
            .then(data => {
                console.log('Success:', data);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }


    return (
        <div>
            <h4>Authentication Details</h4>
            <form onSubmit={handleAuthenticationDetailsSubmit}>
                <div className="mb-3">
                    <label htmlFor="name" className="form-label">Connector ID</label>
                    <input type="text" className="form-control" id="name" name="connectorId" defaultValue="232" />
                </div>
                <div className="mb-3">
                    <label htmlFor="url" className="form-label">EventJson</label>
                    <input type="text" className="form-control" id="url" name="eventJson" defaultValue={eventJson} />
                </div>
                <button type="submit" className="btn btn-primary">Submit</button>
            </form>
        </div>
    );
};

export default TestAuthenticationDetails;