import { useLocation } from "react-router-dom";
import Events from "./Events";

const ManageEvent = ({ outlet }: { outlet: React.ReactNode }) => {
    const location = useLocation();

    return (
        <div>
            {location.pathname === "/manage/events" ? (<>
                <h1>Manage Event</h1>
                <Events />
            </>) : (
                outlet
            )}
        </div>
    );
};

export default ManageEvent;