import { useEffect, useState } from "react";
import io from 'socket.io-client';
import { JsonEditor } from 'json-edit-react';

const LOG_COLORS = {
    debug: '#f8f9fa',
    info: '#e9ecef',
    warn: '#fcf3cf',
    error: '#f8d7da',
    audit: '#d4edda',
    axiosError: '#f8d7da'
}

interface Log {
    key: keyof typeof LOG_COLORS;
    groupId: number;
    log: any;
    title: string;
}

const LogsPage = () => {
    const [logs, setLogs] = useState<Log[]>([]);
    const [connected, setConnected] = useState(false);
    const [showAuditLogs, setShowAuditLogs] = useState(true);
    const [showInfoLogs, setShowInfoLogs] = useState(true);
    const [showWarnLogs, setShowWarnLogs] = useState(true);
    const [showErrorLogs, setShowErrorLogs] = useState(true);
    const [showDebugLogs, setShowDebugLogs] = useState(true);
    const [expandAll, setExpandAll] = useState(false);
    const [groupIdFilter, setGroupIdFilter] = useState<string>('');
    const [groupByGroupId, setGroupByGroupId] = useState(false);

    useEffect(() => {
        const socket = process.env.NODE_ENV === 'development' ? io('http://localhost:4001') : io();

        socket.on('connect', () => {
            setConnected(true);
        });

        socket.on('liveTrailData', (data: Log) => {
            setLogs((prevLogs) => [...prevLogs, data]);
        });

        socket.on('disconnect', () => {
            setConnected(false);
        });

        return () => {
            socket.disconnect();
        };
    }, []);

    const handleClearLogs = () => {
        setLogs([]);
    }

    const groupedLogs = logs.reduce((acc, log) => {
        if (!acc[log.groupId]) {
            acc[log.groupId] = [];
        }
        acc[log.groupId].push(log);
        return acc;
    }, {} as { [key: number]: Log[] });

    return (
        <div>
            <h1>Logs</h1>
            <div>
                <p>Connection Status: <span className={`badge ${connected ? 'bg-success' : 'bg-danger'}`}>{connected ? 'Connected' : 'Disconnected'}</span><button className="btn btn-sm btn-danger badge ms-1" onClick={handleClearLogs}>Clear Logs</button></p>
                <div className="form-check">
                    <input 
                        className="form-check-input"
                        type="checkbox"
                        checked={showInfoLogs}
                        onChange={() => setShowInfoLogs(!showInfoLogs)}
                    />
                    <label className="form-check-label">
                        Info
                    </label>
                </div>
                <div className="form-check">
                    <input 
                        className="form-check-input"
                        type="checkbox"
                        checked={showWarnLogs}
                        onChange={() => setShowWarnLogs(!showWarnLogs)}
                    />
                    <label className="form-check-label">
                        Warn
                    </label>
                </div>
                <div className="form-check">
                    <input 
                        className="form-check-input"
                        type="checkbox"
                        checked={showErrorLogs}
                        onChange={() => setShowErrorLogs(!showErrorLogs)}
                    />
                    <label className="form-check-label">
                        Error
                    </label>
                </div>
                <div className="form-check">
                    <input 
                        className="form-check-input"
                        type="checkbox"
                        checked={showDebugLogs}
                        onChange={() => setShowDebugLogs(!showDebugLogs)}
                    />
                    <label className="form-check-label">
                        Debug
                    </label>
                </div>
                <div className="form-check">
                    <input 
                        className="form-check-input"
                        type="checkbox"
                        checked={showAuditLogs}
                        onChange={() => setShowAuditLogs(!showAuditLogs)}
                    />
                    <label className="form-check-label">
                        Audit Logs
                    </label>
                </div>
                <div className="form-check">
                    <input 
                        className="form-check-input"
                        type="checkbox"
                        checked={expandAll}
                        onChange={() => setExpandAll(!expandAll)}
                    />
                    <label className="form-check-label">
                        Expand All
                    </label>
                </div>
                <div className="form-check">
                    <input 
                        className="form-check-input"
                        type="checkbox"
                        checked={groupByGroupId}
                        onChange={() => setGroupByGroupId(!groupByGroupId)}
                    />
                    <label className="form-check-label">
                        Group by Group ID
                    </label>
                </div>
                <div className="form-input">
                    <label className="form-input-label">
                        Group ID Filter
                    </label>
                    <input 
                        className="form-input"
                        type="input"
                        value={groupIdFilter}
                        onChange={(e) => setGroupIdFilter(e.target.value)}
                    />
                </div>
            </div>
            <ul>
                {groupByGroupId ? (
                    Object.keys(groupedLogs).map((groupId) => (
                        <div className="accordion" id={`accordionGroup${groupId}`} key={groupId}>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id={`heading${groupId}`}>
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${groupId}`} aria-expanded="false" aria-controls={`collapse${groupId}`}>
                                        Group ID: {groupId}
                                    </button>
                                </h2>
                                <div id={`collapse${groupId}`} className="accordion-collapse collapse" aria-labelledby={`heading${groupId}`} data-bs-parent={`#accordionGroup${groupId}`}>
                                    <div className="accordion-body">
                                        {groupedLogs[parseInt(groupId)].filter(({key}) => (showInfoLogs && key === 'info') || (showWarnLogs && key === 'warn') || (showErrorLogs && (key === 'error' || key === 'axiosError')) || (showDebugLogs && key === 'debug') || (showAuditLogs && key === 'audit')).map(({key, log, title}, index) => (
                                            <div key={index} style={{ marginBottom: '20px', backgroundColor: LOG_COLORS[key], padding: '5px' }}>
                                                {title && <p>{title}</p>}
                                                <JsonEditor
                                                    data={ log }
                                                    rootName={key}
                                                    collapse={!expandAll}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    logs.filter(({groupId}) => groupIdFilter !== '' ? groupId.toString() === groupIdFilter : true).filter(({key}) => (showInfoLogs && key === 'info') || (showWarnLogs && key === 'warn') || (showErrorLogs && (key === 'error' || key === 'axiosError')) || (showDebugLogs && key === 'debug') || (showAuditLogs && key === 'audit')).map(({key, log, title, groupId}, index) => (
                        <div key={index} style={{ marginBottom: '20px', backgroundColor: LOG_COLORS[key], padding: '5px' }}>
                            <p>Group ID: {groupId}</p>
                            {title && <p>{title}</p>}
                            <JsonEditor
                                data={ log }
                                rootName={key}
                                collapse={!expandAll}
                            />
                        </div>
                    ))
                )}
            </ul>
        </div>
    );
}

export default LogsPage;