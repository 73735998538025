import React from 'react';

const RemoveBadgeButton: React.FC<{ onClick: () => void }> = ({ onClick }) => {
    return (
        <button 
            className="btn" 
            onClick={onClick} 
            style={{ display: 'flex', alignItems: 'center' }}
        >
            <span className="pill badge bg-danger">REMOVE</span>
        </button>
    );
};

const StatusBadgeButton: React.FC<{ status: string; onClick: () => void }> = ({ status, onClick }) => {
    const badgeClass = status === 'active' ? 'bg-success' : 'bg-warning';

    return (
        <button 
            className={`btn ${badgeClass}`} 
            onClick={onClick} 
            style={{ display: 'flex', alignItems: 'center' }}
        >
            <span className={`badge ${badgeClass}`}>{status.toUpperCase()}</span>
        </button>
    );
};

const DropdownButton: React.FC<{ selectedItem?: {name: string, id: number, color: string}; items: {name: string, id: number, color: string}[]; onClick: (event: any, id: number) => void }> = ({ selectedItem, items, onClick }) => {
    if (!selectedItem) return null;
    return (
        <div className="btn-group">
            <button className={`btn btn-${selectedItem?.color || "primary"} btn-sm dropdown-toggle`} type="button" data-bs-toggle="dropdown" aria-expanded="false">
                {selectedItem.name}
            </button>
            <ul className="dropdown-menu">
                {items.map((item) => (
                    <li key={item.id}><button className="dropdown-item" onClick={(e) => onClick(e, item.id)}>{item.name}</button></li>
                ))}
            </ul>
        </div>
    );
};

export { RemoveBadgeButton, StatusBadgeButton, DropdownButton };
