const TestNewConnector = () => {
    const handleSubmit = (e: any) => {
        e.preventDefault();

        console.log(e.currentTarget);

        const formData = new FormData(e.currentTarget);
        const eventData: any = {
            eventName: "NEW_CONNECTOR",
            event: {
                name: formData.get("name"),
                url: formData.get("url"),
                logo: formData.get("logoUrl"),
                documentation_url: formData.get("documentationUrl"),
                description: formData.get("description")
            }
        };

        if (formData.get("connectorId")) {
            eventData.connectorId = formData.get("connectorId");
        }

        fetch('/api/events', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(eventData)
        })
        .then(response => response.json())
        .then(data => {
            console.log('Success:', data);
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    }

    return (
        <div>
            <h4>New Connector</h4>
            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label htmlFor="name" className="form-label">Name</label>
                    <input type="text" className="form-control" id="name" name="name" defaultValue="Google Calendar" />
                </div>
                <div className="mb-3">
                    <label htmlFor="url" className="form-label">URL</label>
                    <input type="text" className="form-control" id="url" name="url" defaultValue="https://calendar.google.com" />
                </div>
                <div className="mb-3">
                    <label htmlFor="documentationUrl" className="form-label">Documentation URL</label>
                    <input type="text" className="form-control" id="documentationUrl" name="documentationUrl" defaultValue="https://developers.google.com/calendar/api/guides/overview" />
                </div>
                <div className="mb-3">
                    <label htmlFor="logoUrl" className="form-label">Logo URL</label>
                    <input type="text" className="form-control" id="logoUrl" name="logoUrl" defaultValue="https://lh3.googleusercontent.com/MxyI2wjFGTDQ0Vs_ydI9x0je9SeKQqwiYgvXHS9z9Tf-w0FHvkTXTfJLtkL6vUc28IPTdDDKGnmL_gyvsHLo9i67vNTGF7FdzMUblcGeSv24_MtT3dA" />
                </div>
                <div className="mb-3">
                    <label htmlFor="description" className="form-label">Description</label>
                    <input type="text" className="form-control" id="description" name="description" defaultValue="This is a test connector" />
                </div>
                <div className="mb-3">
                    <label htmlFor="connectorId" className="form-label">Connector ID</label>
                    <input type="number" className="form-control" id="connectorId" name="connectorId" defaultValue="232" />
                </div>

                <button type="submit" className="btn btn-primary">Submit</button>
            </form>
        </div>
    );
};

export default TestNewConnector;