import TestMetadata from "./components/TestMetadata";
import TestNewConnector from "./components/TestNewConnector";
import ResetConnector from "./components/ResetConnector";
import ManageEvent from "./components/ManageEvent";
import TestAuthenticationTypes from "./components/TestAuthenticationTypes";
import TestAuthenticationDetails from "./components/TestAuthenticationDetails";
const EventTesting = {
    TestMetadata,
    ResetConnector,
    TestNewConnector,
    TestAuthenticationTypes,
    TestAuthenticationDetails
};

const Event = {
    Testing: EventTesting,
    ManageEvent
};

export default Event;

