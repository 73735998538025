import { ActionType } from '../../types/shared.types';
export const ActionTagsInput = ({action, onRemove, onEdit}: {action: ActionType, onRemove: () => void, onEdit: () => void}) => {
    return (<>
        <div style={{display: 'flex', gap: '5px', marginBottom: '5px', alignItems: 'center'}}>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '5px', border: '1px solid #ced4da', borderRadius: '4px', padding: '5px', alignItems: 'center', flex: 1 }}>
                <span>When</span>
                <span className={`badge bg-primary`}>{action.lookup_category_txt}</span>
                <span style={{fontWeight: 'bold'}}>{action.lookup_txt}</span>
                <span>Is</span>
                <span className={`badge bg-primary`}>{action.conditional_category_txt}</span>
                <span style={{fontWeight: 'bold'}}>{action.conditional_txt}</span>
                <span>Then</span>
                <span className={`badge bg-primary`}>{action.action_category_txt}</span>
                <span style={{fontWeight: 'bold'}}>{action.action_txt}</span>
                <span className={`badge bg-primary`}>{action.failure_txt}</span>
            </div>
            <button className="btn btn-danger btn-sm" onClick={onRemove}>Remove</button>
            <button className="btn btn-primary btn-sm" onClick={onEdit}>Edit</button>
        </div>
        </>
    )
}