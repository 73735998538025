import { useState } from "react";
import { DropdownButton } from "../../../components/ui/Button";
import {SelectIssueType, SelectIssueStatus} from "./IssueSelect";
import { ActionTagsInput } from "../../../components/ui/Form";
import { IssueStatus, IssueType, JiraTemplate, TaskAction } from "../../../types/jira.types";
import { ActionModal } from "./ActionModal";

const JiraTask = ({ jiraTemplate, issueTypes, issueStatuses, onRemove, jiraTemplates }: { jiraTemplate: JiraTemplate,  issueTypes: IssueType[], issueStatuses: IssueStatus[], onRemove: (id: number) => void, jiraTemplates: JiraTemplate[] }) => {
    const [taskName, setTaskName] = useState(jiraTemplate.task_name);
    const [description, setDescription] = useState(jiraTemplate.description);
    const [assigneeEmail, setAssigneeEmail] = useState(jiraTemplate.assignee_email); // Added state for assignee email
    const [issueType, setIssueType] = useState(jiraTemplate.issue_type || issueTypes[0]);
    const [issueStatus, setIssueStatus] = useState(jiraTemplate.default_status || issueStatuses[0]);
    const [unsavedChanges, setUnsavedChanges] = useState<boolean>(jiraTemplate.saved === false);
    const [expanded] = useState<boolean>(jiraTemplate.new === true);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [actions, setActions] = useState<TaskAction[]>(jiraTemplate.actions || []);
    const [selectedParentTask, setSelectedParentTask] = useState<{name: string, id: number, color: string}>({
        name: jiraTemplate.parent_task?.task_name || "None",
        id: jiraTemplate.parent_task?.task_id || 0,
        color: "secondary" || "danger",
    });
    const [parentTaskOptions] = useState<{name: string, id: number, color: string}[]>(jiraTemplates.filter(template => template.issue_type?.name === "Task").map(template => ({
        name: template.task_name,
        id: template.task_id,
        color: "secondary"
    })));


    const handleSummaryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTaskName(e.target.value);
        setUnsavedChanges(true);
    };

    const handleDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setDescription(e.target.value);
        setUnsavedChanges(true);
    };

    const handleAssigneeEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => { // New handler for assignee email
        setAssigneeEmail(e.target.value);
        setUnsavedChanges(true);
    };

    const handleIssueTypeChange = (newIssueType: IssueType) => {
        setIssueType(newIssueType);
        setUnsavedChanges(true);
    }

    const handleIssueStatusChange = (newIssueStatus: IssueStatus) => {
        setIssueStatus(newIssueStatus);
        setUnsavedChanges(true);
    }

    const handleSaveAction = (action: TaskAction) => {
        setActions([...actions, action]);
        setUnsavedChanges(true);
    }

    const handleParentTaskChange = (event: any, id: number) => {
        const newParentTask = parentTaskOptions.find(option => option.id === id);
        if (newParentTask) {
            setSelectedParentTask(newParentTask);
            setUnsavedChanges(true);
        }
    }

    const handleSaveTask = async () => {
        const updatedTask = {
            ...jiraTemplate.new ? { ...jiraTemplate, task_id: undefined } : jiraTemplate,
            task_name: taskName,
            description,
            assignee_email: assigneeEmail,
            issue_type_id: issueType.issue_type_id,
            default_status: issueStatus.status_id,
            parent_id: issueType.name === "Subtask" ? selectedParentTask.id : null,
        };

        console.log(updatedTask);

        try {
            const response = await fetch('/api/jira-templates', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedTask),
            });

            if (!response.ok) {
                throw new Error('Failed to save task');
            }

            setUnsavedChanges(false)
        } catch (error) {
            console.error('Error saving task:', error);
        }
    }

    const handleRemoveTask = async () => {
        try {
            const response = await fetch(`/api/jira-templates/${jiraTemplate.task_id}`, {
                method: 'DELETE',
            });

            if (!response.ok) {
                throw new Error('Failed to remove task');
            }

            onRemove(jiraTemplate.task_id); // Call the onRemove prop to update the task list
        } catch (error) {
            console.error('Error removing task:', error);
        }
    }

    const handleRemoveAction = (index: number) => {
        setActions(actions.filter((_, i) => i !== index));
        setUnsavedChanges(true);
    }

    if (!issueStatuses.length && !issueTypes.length) {
        return null;
    }

    return (
        <div className="accordion-item">
            <h2 className="accordion-header" id={`heading-${jiraTemplate.task_id}`}>
                <button className={`accordion-button ${expanded ? '' : 'collapsed'}`} type="button" data-bs-toggle="collapse" data-bs-target={`#collapse-${jiraTemplate.task_id}`} aria-expanded={expanded ? "true" : "false"} aria-controls={`collapse-${jiraTemplate.task_id}`}>
                {unsavedChanges && (<span className="me-2 badge bg-secondary">Not Saved</span>)}
                    {taskName}
                </button>
            </h2>
            <div id={`collapse-${jiraTemplate.task_id}`} className={`accordion-collapse collapse ${expanded ? 'show' : ''}`} aria-labelledby={`heading-${jiraTemplate.task_id}`} data-bs-parent="#accordionExample">
                <div className="accordion-body">
                    <div>
                        <div className="default-status">
                            <label>Issue Type</label>
                            <SelectIssueType issueType={issueType} issueTypes={issueTypes} onChange={handleIssueTypeChange} />
                        </div>
                        {issueType.name === "Subtask" && (
                            <div className="mt-2">
                                <label>Parent Task</label>
                                <DropdownButton selectedItem={selectedParentTask} items={parentTaskOptions} onClick={handleParentTaskChange} />
                            </div>
                        )}
                        <div className="default-status">
                            <label>Default Status</label>
                            <SelectIssueStatus status={jiraTemplate.default_status} issueStatuses={issueStatuses} onChange={handleIssueStatusChange} />
                        </div>
                    </div>
                    <div className="summary-edit mt-2">
                        <label>Task Name</label>
                        <input 
                            type="text" 
                            value={taskName} 
                            onChange={handleSummaryChange} 
                            className="form-control" 
                        />
                    </div>
                    <div className="description-edit mt-2">
                        <label>Description</label>
                        <textarea 
                            value={description} 
                            onChange={handleDescriptionChange} 
                            className="form-control" 
                            rows={4} 
                        />
                    </div>
                    <div className="assignee-edit mt-2"> {/* New section for assignee email */}
                        <label>Assignee Email</label>
                        <input 
                            type="email" 
                            value={assigneeEmail} 
                            onChange={handleAssigneeEmailChange} 
                            className="form-control" 
                        />
                    </div>
                    <div>
                        <div>
                            <label>Actions</label>
                            {/* {actions.map((action, index) => (
                                <ActionTagsInput key={index} action={action} onRemove={() => handleRemoveAction(index)} />
                            ))} */}
                        </div>

                    <button className="btn btn-info mt-2" onClick={() => setShowModal(true)}>
                        Add Action
                    </button>

                    {showModal && (
                        <ActionModal setShowModal={setShowModal} statuses={issueStatuses} onSave={handleSaveAction} />
                    )}
                    </div>
                    <hr />
                    <div className="mt-2 d-flex justify-content-end">
                        <button className="btn btn-primary me-2" onClick={handleSaveTask} disabled={!unsavedChanges}>
                            Save
                        </button>
                        <button className="btn btn-danger" onClick={handleRemoveTask}>
                            Remove
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default JiraTask;
