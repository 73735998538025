import React, { useEffect, useState } from 'react';
import io from 'socket.io-client';
import { Sidebar } from '../../components/Sidebar';
import { Outlet } from 'react-router-dom';
const ServerPage = ({pageItems}: {pageItems: {label: string, path: string}[]}) => {
        return (
        <div>
            <Sidebar items={pageItems} />
            <div className="server-content">
                <Outlet />
            </div>
        </div>
    );
};

export default ServerPage;

