import { Outlet, useLocation, NavLink } from "react-router-dom";
import { Sidebar } from "../../components/Sidebar";

const Breadcrumbs = ({ pageItems }: { pageItems: { label: string, path: string }[] }) => {
    const location = useLocation();
    const pathnames = location.pathname.split("/").filter((x) => x);

    // Generate breadcrumb items by matching the current path with `pageItems`
    const breadcrumbs = pathnames.map((value, index) => {
        const to = `/${pathnames.slice(0, index + 1).join("/")}`;
        const item = pageItems.find((item) => item.path === to);

        return (
            <li key={to} className="breadcrumb-item">
                <NavLink to={to}>{item ? item.label : value}</NavLink>
            </li>
        );
    });

    return (
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                <li className="breadcrumb-item">
                    <NavLink to="/">Home</NavLink>
                </li>
                {breadcrumbs}
            </ol>
        </nav>
    );
};

const ManagePage = ({ pageItems }: { pageItems: { label: string, path: string }[] }) => {
    return (
        <div className="d-flex" style={{ height: '87vh' }}>
                <Sidebar items={pageItems} />
            <div className="manage-content overflow-auto">
                {/* Breadcrumbs Section */}
                <Breadcrumbs pageItems={pageItems} />
                
                {/* Outlet for the main content */}
                <Outlet />
            </div>
        </div>
    );
};

export default ManagePage;
