const eventJson = '[ { "authentication_type": "OAuth 2.0", "authentication_type_id": 1 }, { "authentication_type": "Google OAuth2", "authentication_type_id": 21 }, { "authentication_type": "Service Account Authentication", "authentication_type_id": 27 } ]';

const TestAuthenticationTypes = () => {
    const handleAuthenticationTypesSubmit = (e: any) => {
        e.preventDefault();

        const formData = new FormData(e.currentTarget);
        const eventData: any = {
            eventName: "AUTHENTICATION_TYPES",
            connectorId: formData.get("connectorId"),
            event: JSON.parse(formData.get("eventJson") as string)
        };
        
        fetch('/api/events', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(eventData)
        }).then(response => response.json())
            .then(data => {
                console.log('Success:', data);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }


    return (
        <div>
            <h4>Authentication Types</h4>
            <form onSubmit={handleAuthenticationTypesSubmit}>
                <div className="mb-3">
                    <label htmlFor="name" className="form-label">Connector ID</label>
                    <input type="text" className="form-control" id="name" name="connectorId" defaultValue="232" />
                </div>
                <div className="mb-3">
                    <label htmlFor="url" className="form-label">EventJson</label>
                    <input type="text" className="form-control" id="url" name="eventJson" defaultValue={eventJson} />
                </div>
                <button type="submit" className="btn btn-primary">Submit</button>
            </form>
        </div>
    );
};

export default TestAuthenticationTypes;