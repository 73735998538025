import React from 'react';

import Navbar from './components/Navbar';
import { Outlet } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Navbar />
      </header>
      <main>
        <Outlet />
      </main>
      <footer>
        <p>Copyright 2024</p>
      </footer>
    </div>
  );
}

export default App;
