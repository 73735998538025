import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

interface SidebarItem {
    label: string;
    path: string;
    children?: SidebarItem[];
    hasPage?: boolean;
}

interface SidebarItemProps {
    item: SidebarItem;
    depth: number;
    parentPath: string;
}

const SidebarItem: React.FC<SidebarItemProps> = ({ item, depth, parentPath }) => {
    const [isOpen, setIsOpen] = useState(false);
    const fullPath = `${parentPath}/${item.path}`;
    const navigate = useNavigate();
    const handleToggle = () => {
        if (item.hasPage) {
            navigate(fullPath);
        }
            
        setIsOpen(!isOpen);
    };

    return (
        <div style={{ paddingLeft: `${depth * 10}px` }}>
            <div
                onClick={handleToggle}
                className="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
            >
                {item.children ? (
                    <>
                        {item.hasPage && <NavLink to={fullPath} className="w-100 text-decoration-none text-reset">{item.label}</NavLink>}
                        {!item.hasPage && item.label}
                        <i className={`bi ${isOpen ? 'bi-chevron-up' : 'bi-chevron-down'}`}></i>
                    </>
                ) : (
                    <NavLink to={fullPath} className="w-100 text-decoration-none text-reset">
                        {item.label}
                    </NavLink>
                )}
            </div>

            {isOpen && item.children && (
                <div>
                    {item.children.map((child) => (
                        <SidebarItem
                            key={`${fullPath}/${child.path}`}
                            item={child}
                            depth={depth + 1}
                            parentPath={fullPath}
                        />
                    ))}
                </div>
            )}

        </div>
    );
};

interface SidebarProps {
    items: SidebarItem[];
}

export const Sidebar: React.FC<SidebarProps> = ({ items }) => {
    return (
        <div className="sidebar">
            <div className="list-group list-group-flush">
                {items.map((item) => (
                    <SidebarItem key={item.path} item={item} depth={0} parentPath="/manage" />
                ))}
            </div>
        </div>
    );
};
