import React, { useState } from 'react';

const ResetConnector = () => {
    const [connectorId, setConnectorId] = useState('');
    const [message, setMessage] = useState('');

    const handleReset = async () => {
        try {
            const response = await fetch('/api/events/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ eventName: "RESET_CONNECTOR", connectorId: connectorId, event: [] })
            }).then(response => response.json())
                .then(data => {
                    if (data.events[0].success) {
                        setMessage('Connector reset successfully.');
                    } else {
                        setMessage('Failed to reset connector.');
                    }
                });
        } catch (error) {
            setMessage('An error occurred while resetting the connector.');
        }
    };

    return (
        <div>
            <h2>Reset Connector</h2>
            <input
                type="text"
                value={connectorId}
                onChange={(e) => setConnectorId(e.target.value)}
                placeholder="Enter Connector ID"
            />
            <button onClick={handleReset}>Reset Connector</button>
            {message && <p>{message}</p>}
        </div>
    );
};

export default ResetConnector;
