import { useEffect, useState } from "react";
import JiraTask from "../../features/jira/components/JiraTask";
import { IssueType, JiraTemplate, IssueStatus } from "../../types/jira.types";

const JiraPage = () => {
    const [jiraTemplates, setJiraTemplates] = useState<JiraTemplate[]>([]);
    const [issueTypes, setIssueTypes] = useState<IssueType[]>([]); // State to hold issue types
    const [issueStatuses, setIssueStatuses] = useState<IssueStatus[]>([]);
    const [loading, setLoading] = useState(true); // Loading state for tasks

    useEffect(() => {
        const fetchData = async () => {
            try {
                const jiraTemplatesResponse = await fetch('/api/jira-templates'); // Fetching tasks
                const jiraTemplatesData = await jiraTemplatesResponse.json();

                jiraTemplatesData.forEach((template: JiraTemplate) => {
                    if (template.parent_id) {
                        const parentTask = jiraTemplatesData.find((parent: JiraTemplate) => parent.task_id === template.parent_id);
                        if (parentTask) {
                            template.parent_task = parentTask;
                        }
                    }
                });

                console.log(jiraTemplatesData);
                setJiraTemplates(jiraTemplatesData); // Set the fetched tasks

                const issueTypesResponse = await fetch('/api/jira/issue-types'); // Fetching issue types
                const issueTypesData = await issueTypesResponse.json();
                setIssueTypes(issueTypesData); // Set the fetched issue types

                const issueStatusesResponse = await fetch('/api/jira/issue-statuses');
                const issueStatusesData = await issueStatusesResponse.json();
                setIssueStatuses(issueStatusesData);
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false); // Set loading to false after fetching
            }
        };

        fetchData();
    }, []);

    const addNewTemplate = () => {
        const newJiraTemplate = {
            task_id: jiraTemplates.length + 1000, // Incremental ID based on current tasks
            task_name: `Task ${jiraTemplates.length + 1}`,
            description: '',
            assignee_email: "Unassigned",
            saved: false,
            new: true
        };
        setJiraTemplates([...jiraTemplates, newJiraTemplate]);
    }

    const removeTask = (taskId: number) => {
        setJiraTemplates(jiraTemplates.filter(jiraTemplate => jiraTemplate.task_id !== taskId));
    }

    return (
        <div>
            <h3>Templates</h3>
            {loading ? ( // Conditional rendering based on loading state
                <div>Loading tasks...</div>
            ) : (
                <div className="issue-list accordion mt-2">
                    {jiraTemplates.map((jiraTemplate) => (
                        <JiraTask key={jiraTemplate.task_id} jiraTemplate={jiraTemplate} jiraTemplates={jiraTemplates} onRemove={removeTask} issueTypes={issueTypes} issueStatuses={issueStatuses} /> // Passing issue types to JiraTask
                    ))}
                </div>
            )}
            <button className="btn btn-primary mt-2" onClick={addNewTemplate}>
                Add New Template
            </button>
        </div>
    );
};

export default JiraPage;
