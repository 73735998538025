import { useState } from "react";
import { DropdownButton } from "../../../components/ui/Button";
import { IssueStatus, IssueType } from "../../../types/jira.types";

const getColor = (statusName: string | undefined): string => {
    if (statusName === "Pending") {
        return "secondary";
    } else if (statusName === "Completed" || statusName === "Deployed") {
        return "success";
    }
    return 'primary';
}

const SelectIssueStatus = ({ status, issueStatuses, onChange }: { status?: IssueStatus, issueStatuses: IssueStatus[], onChange: (newIssueStatus: IssueStatus) => void }) => {
    const [statuses] = useState(issueStatuses.map(is => ({id: is.status_id, name: is.name, color: getColor(is.name)})))
    const [selectedStatus, setSelectedStatus] = useState({
        id: status?.status_id || issueStatuses[0].status_id,
        name: status?.name || issueStatuses[0].name,
        color: getColor(status?.name || issueStatuses[0].name)
    });

    const onClick = (e: any, id: number) => {
        e.preventDefault();
        let newJiraStatus = issueStatuses.find(status => status.status_id === id);

        if (newJiraStatus) {
            setSelectedStatus({
                id: newJiraStatus.status_id,
                name: newJiraStatus.name,
                color: getColor(status?.name || issueStatuses[0].name)
            });
            onChange(newJiraStatus)
        }
    }

    return (
        <div>
            <DropdownButton selectedItem={selectedStatus} items={statuses} onClick={onClick} />
        </div>
    );
};

const SelectIssueType = ({ issueType, issueTypes, onChange }: { issueType?: IssueType, issueTypes: IssueType[], onChange: (issueType: IssueType) => void }) => {
    const [issTypes] = useState(issueTypes.map(it => ({id: it.issue_type_id, name: it.name, color: 'primary'})));
    const [selectedIssueType, setSelectedIssueType] = useState({
        id: issueType?.issue_type_id || issueTypes[0].issue_type_id,
        name: issueType?.name || issueTypes[0].name,
        color: 'primary'
    })

    const onClick = (e: any, id: number) => {
        e.preventDefault();
        let newIssueType = issueTypes.find(type => type.issue_type_id === id);

        if (newIssueType) {
            setSelectedIssueType({
                id: newIssueType.issue_type_id,
                name: newIssueType.name,
                color: 'primary'
            });
            onChange(newIssueType);
        }
    }

    return (
        <div>
            <DropdownButton selectedItem={selectedIssueType} items={issTypes} onClick={onClick} />
        </div>
    );
};


export { SelectIssueStatus, SelectIssueType };

