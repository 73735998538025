const NewItemComponent = ({ newItemLabel }: { newItemLabel: string }) => {
    return <div>New {newItemLabel}</div>;
}

const List = ({ listName, items, newItemLabel, onAddNewItem }: { listName: string, items: React.ReactNode[], newItemLabel?: string, onAddNewItem?: () => void }) => {
    return (<div style={{ borderRadius: '10px', border: '1px solid #ccc', padding: '10px' }}>
        <h5>{listName}</h5>

        {items.map((item, index) => (
            <ListItemComponent key={index} content={item} index={index} last={index == items.length - 1 && !newItemLabel} />
        ))}
        {newItemLabel && <ListItemComponent key={items.length} content={<NewItemComponent newItemLabel={newItemLabel} />} onClick={onAddNewItem} index={items.length} last={true} />}
    </div>
    );
}
const ExpandableList = ({ listName, items }: { listName: string, items: { itemName: string, itemContent: React.ReactNode }[] }) => {
    return (
        <div style={{ borderRadius: '10px', border: '1px solid #ccc', padding: '10px' }}>
            <h5>{listName}</h5>
            <div className="accordion" id="accordionExample">
                {items.map((item, index) => (
                    <div className="accordion-item" key={index}>
                        <h2 className="accordion-header" id={`heading${index}`}>
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} aria-expanded="false" aria-controls={`collapse${index}`}>
                                {item.itemName}
                            </button>
                        </h2>
                        <div id={`collapse${index}`} className="accordion-collapse collapse" aria-labelledby={`heading${index}`} data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <ExpandableListItemComponent content={item.itemContent} />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );

}

const ExpandableListItemComponent = ({ content }: { content: React.ReactNode }) => {
    return (<>
        {content}
    </>);
}

const ListItemComponent = ({ content, index, last, onClick }: { content: React.ReactNode, index: number, last: boolean, onClick?: () => void }) => {
    return (<div onClick={onClick} style={{ padding: '5px', cursor: onClick ? 'pointer' : 'default', borderBottom: index < 0 ? 'none' : '1px solid #ccc', borderTop: index == 0 ? '1px solid #ccc' : 'none', borderRadius: last && index == 0 ? '5px 5px 5px 5px' : index == 0 ? '5px 5px 0 0' : last ? '0 0 5px 5px' : 'none', borderLeft: '1px solid #ccc', borderRight: '1px solid #ccc' }}>
        {content}
    </div>);
}

export { List, ExpandableList };
