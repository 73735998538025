import { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import SchemaBuilder from "../../features/schema/components/SchemaBuilder";

const SchemaPage = () => {
    const [schemas, setSchemas] = useState([]);
    const [selectedSchema, setSelectedSchema] = useState(null);

    useEffect(() => {
        fetchSchemas();
    }, []);

    const fetchSchemas = async () => {
        const response = await fetch('/api/schemas');
        const data = await response.json();
        setSchemas(data);
    };

    return (
        <div>
            <h3>Schemas</h3>
            <div style={{ display: 'flex' }}>
                <SchemaList 
                    schemas={schemas} 
                    onSelectSchema={setSelectedSchema} 
                />
                <SchemaBuilder 
                    selectedSchema={selectedSchema} 
                    onSchemaCreated={fetchSchemas} 
                />
            </div>
        </div>
    )
}


const SchemaList = ({ schemas, onSelectSchema }: { schemas: any, onSelectSchema: any }) => {
    return (
        <div style={{ width: '30%', marginRight: '20px' }}>
            <h4>Existing Schemas</h4>
            <ul>
                {schemas.map((schema: any) => (
                    <li key={schema.id} onClick={() => onSelectSchema(schema)}>
                        {schema.schema_name}
                    </li>
                ))}
            </ul>
        </div>
    );
};


export default SchemaPage;