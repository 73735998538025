import React, { useEffect } from "react";
import {
    createBrowserRouter,
    Outlet
} from "react-router-dom";
import App from "../App";
import EventsPage from "../pages/events/EventsPage";
import JiraPage from "../pages/jira/JiraPage";
import ManagePage from "../pages/manage/ManagePage";
import SchemaPage from "../pages/schemas/SchemaPage";
import JiraTemplatesPage from "../pages/jira/JiraTemplatesPage";
import ServerPage from "../pages/server/ServerPage";
import LogsPage from "../pages/logs/LogsPage";
import MetadataPage from "../pages/schemas/MetadataPage";
import Event from "../features/events/events";



const managePageNavigationItems = [
    {
        label: "Prime",
        path: "jira",
        children: [
            {
                label: "Tasks",
                path: "templates",
            }
        ]
    },
    {
        label: "Events",
        path: "events",
        hasPage: true,
        children: [
            {
                label: "Testing",
                path: "testing",
                children: [
                    {
                        label: "New Connector Event",
                        path: "new-connector",
                    },
                    {
                        label: "Metadata Event",
                        path: "metadata",
                    },
                    {
                        label: "Authentication Types",
                        path: "authentication-types",
                    },
                    {
                        label: "Authentication Details",
                        path: "authentication-details",
                    },
                    {
                        label: "Reset Connector",
                        path: "reset-connector",
                    }
                ]
            }
            
        ]
    },
    {
        label: "Schemas",
        path: "schemas",
    }
]

const serverPageNavigationItems = [
    {
        label: "Logs",
        path: "logs",
    }
]

const SwaggerRedirect = () => {
    useEffect(() => {
        if (process.env.NODE_ENV === 'development') {
            window.location.href = process.env.SWAGGER_URL || "http://localhost:4001/swagger";
        } else {
            window.location.reload();
        }
    }, []);

    return null;
};

const Router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        errorElement: <div>Error</div>,
        children: [
            {
                path: "manage",
                element: <ManagePage pageItems={managePageNavigationItems} />,
                children: [
                    {
                        path: "jira",
                        element: <GenericNestedRoute />,
                        children: [
                            {
                                path: "templates",
                                element: <JiraTemplatesPage />,
                            }
                        ]
                    },
                    {
                        path: "events",
                        element: <Event.ManageEvent outlet={<GenericNestedRoute />} />,
                        children: [
                            {
                                path: "testing",
                                element: <GenericNestedRoute />,
                                children: [
                                    {
                                        path: "new-connector",
                                        element: <Event.Testing.TestNewConnector />,
                                    },
                                    {
                                        path: "metadata",
                                        element: <Event.Testing.TestMetadata />,
                                    },
                                    {
                                        path: "authentication-types",
                                        element: <Event.Testing.TestAuthenticationTypes />,
                                    },
                                    {
                                        path: "authentication-details",
                                        element: <Event.Testing.TestAuthenticationDetails />,
                                    },
                                    {
                                        path: "reset-connector",
                                        element: <Event.Testing.ResetConnector />,
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        path: "schemas",
                        element: <SchemaPage />,
                    }
                ],
            },
            {
                path: "swagger",
                element: <SwaggerRedirect />
            },
            {
                path: "server",
                element: <ServerPage pageItems={serverPageNavigationItems} />,
                children: [
                    {
                        path: "logs",
                        element: <LogsPage />,
                    }
                ],
            }
        ],
    }
]);

function GenericNestedRoute() {
    return (
        <Outlet />
    )
}

export default Router;

